// Cell Renderer Function - https://www.ag-grid.com/vue-grid/component-cell-renderer/#cell-renderer-function-3
import CorporationTypes from "../../consts/CorporationTypes";

const BooleanCellRenderer = (trueIcon, falseIcon) => {
  return (params) => {
    if (params == null) {
      return null;
    }
    const opacity = params.colDef?.editable ? 1.0 : 0.65;
    const icon = params.value ? trueIcon || "mdi-checkbox-marked" : falseIcon || "mdi-checkbox-blank-outline";
    return `<span><i class="mdi v-icon ${icon}" style="opacity: ${opacity}"></i></span>`;
  };
};

const CheckmarkCellRenderer = (checkIcon) => {
  return (params) => {
    if (params == null) {
      return null;
    }
    const opacity = params.colDef?.editable ? 1.0 : 0.65;
    return params.value
      ? `<span><i class="mdi v-icon ${checkIcon || "mdi-check"}" style="opacity: ${opacity}"></i></span>`
      : null;
  };
};

const CheckboxCellRenderer = () => {
  return (params) => {
    if (params == null) {
      return null;
    }
    const checked = params.value ? "checked" : "";
    const disabled = params.colDef?.editable ? "" : "disabled";
    return `<input type='checkbox' ${checked} ${disabled} />`;
  };
};

const WithIconCellRenderer = (option) => {
  return (params) => {
    if (params == null) {
      return null;
    }
    let yes;
    if (typeof option?.matcher === "function") {
      yes = option.matcher(params);
    } else if (typeof option?.matcher === "string") {
      yes = Boolean(params.data[option.matcher]);
    } else {
      yes = Boolean(params.value);
    }
    let value = option?.useFormatter ? params.valueFormatted : params.value;
    if (value == null) value = "";
    const icon = yes ? option?.true || "mdi-checkbox-blank-circle-outline" : option?.false || "mdi-close";
    if (option?.position === "right") {
      return `<span>${value}<i class="mdi v-icon ${icon}" style="font-size: 16px; margin-left:5px"></i></span>`;
    } else {
      return `<span><i class="mdi v-icon ${icon}" style="font-size: 16px; margin-right:5px"></i>${value}</span>`;
    }
  };
};

const SingleIconCellRenderer = (option) => {
  return (params) => {
    if (params == null) {
      return null;
    }
    let showIcon;
    if (typeof option?.matcher === "function") {
      showIcon = option.matcher(params);
    } else {
      showIcon = Boolean(params.data[option.matcher]);
    }
    let style = "font-size: 16px; margin-left:5px;";
    if (option?.style) {
      style = option?.style;
    }
    const value = params.value == null ? "" : params.value;
    if (showIcon) {
      if (option?.position === "right") {
        return `<span>${value}<i class="mdi v-icon ${option?.icon}" style="${style}"></i></span>`;
      } else {
        return `<span><i class="mdi v-icon ${option?.icon}" style="${style}"></i>${value}</span>`;
      }
    } else {
      return `<span>${value}</span>`;
    }
  };
};

const LockedCellRenderer = (option) => {
  return SingleIconCellRenderer({ ...option, icon: "mdi-lock" });
};

const ProtectCellRenderer = (option) => {
  return SingleIconCellRenderer({ ...option, icon: "mdi-pencil-off" });
};

const ProductClassCellRenderer = (option) => {
  return (params) => {
    const style = "color:red; margin-left:5px;";
    if (params.data["recordType"] == 1 && params.data["productStatus"] < 5) {
      if (option.corporateType == CorporationTypes.CAFEREO && params.data["productStatus"] < 5) {
        return `<span><i class="mdi v-icon mdi-new-box" style="${style}"></i></span>`;
      } else if (option.corporateType == CorporationTypes.MAKER && params.data["productStatus"] < 5) {
        return `<span><i class="mdi v-icon mdi-new-box" style="${style}"></i></span>`;
      } else if (option.corporateType == CorporationTypes.VENDOR && params.data["productStatus"] < 3) {
        return `<span><i class="mdi v-icon mdi-new-box" style="${style}"></i></span>`;
      }
      return "";
    }
  };
};

export {
  BooleanCellRenderer,
  CheckmarkCellRenderer,
  CheckboxCellRenderer,
  SingleIconCellRenderer,
  WithIconCellRenderer,
  LockedCellRenderer,
  ProtectCellRenderer,
  ProductClassCellRenderer,
};
