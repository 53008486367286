<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-hover v-slot="{ hover }">
        <v-btn v-on="on" @click="$emit('click', $event)" icon small class="mx-2" :disabled="disabled">
          <v-icon :size="!nonZoom && hover ? 30 : 15" style="transition: all 0.1s 0s linear">{{ icon }}</v-icon>
        </v-btn>
      </v-hover>
    </template>
    <span><slot></slot></span>
  </v-tooltip>
</template>

<script>
export default {
  name: "TooltipIconButton",
  props: { icon: { type: String }, "non-zoom": { type: Boolean }, disabled: { type: Boolean, default: false } },
};
</script>
