<template>
  <span @click="onClick">
    <i
      class="mdi v-icon"
      :class="{
        'mdi-checkbox-marked': params.value,
        'mdi-checkbox-blank-outline': !params.value,
      }"
      :style="{
        cursor: cursor,
        opacity: opacity,
      }"
    ></i>
  </span>
</template>

<script>
// import Vue from "vue";
import VueJS from "vue/dist/vue.js";

export default VueJS.extend({
  computed: {
    cursor() {
      return this.disabled ? "auto" : "pointer";
    },
    opacity() {
      return this.disabled ? 0.65 : 1.0;
    },
    disabled() {
      return !this.params.colDef?.editable;
    },
  },
  methods: {
    onClick() {
      const editingRow = this.params.api.node;
      const selectedNodes = this.params.api.getSelectedNodes();
      if (selectedNodes.length > 1 && !selectedNodes.includes(editingRow)) {
        return;
      }
      if (!this.disabled) {
        const colId = this.params.column.colId;
        this.params.api.startEditingCell({
          rowIndex: this.params.rowIndex,
          colKey: this.params.colDef.field,
        });
        this.params.node.setDataValue(colId, !this.params.value);
      }
    },
  },
});
</script>
