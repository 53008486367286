<template>
  <input
    :ref="'input'"
    v-model="value"
    style="width: 100%; outline: none; background-color: #ffffff"
    @change="isChanged"
  />
</template>

<script>
// import Vue from "vue";
import VueJS from "vue/dist/vue.js";
import ConvertUtils from "../../../utils/ConvertUtils";
export default VueJS.extend({
  data() {
    return {
      value: null,
      cancelBeforeStart: true,
      highlightAllOnFocus: true,
    };
  },
  methods: {
    getValue() {
      return ConvertUtils.stringToNumber(this.value);
    },
    isCancelBeforeStart() {
      return false;
    },
    isCancelAfterEnd() {
      return false;
    },
    isChanged() {
      this.value = this.value !== "" ? this.value : null;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.input.focus();
      this.$refs.input.select();
    });
    this.value = this.params.value;
  },
});
</script>
