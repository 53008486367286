<template>
  <span>
    <v-btn v-if="display" @click="btnClickedHandler()">{{ name }}</v-btn>
  </span>
</template>

<script>
// import Vue from "vue";
import VueJS from "vue/dist/vue.js";

export default VueJS.extend({
  computed: {
    name() {
      return this.params.name;
    },
    display() {
      return this.params.value;
    },
  },
  methods: {
    btnClickedHandler() {
      this.params.clicked(this.params.self, this.params);
    },
  },
});
</script>
