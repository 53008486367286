<template>
  <v-dialog v-model="show" :max-width="options.width" persistent scrollable>
    <v-card>
      <v-toolbar color="warning" dark>
        <v-toolbar-title>
          <span class="headline">
            <v-icon>{{ options.icon }}</v-icon
            >{{ options.title }}
          </span>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-subtitle class="my-3 pb-0" v-if="options.subtitle && options.subtitle.length > 0">
        {{ options.subtitle }}
      </v-card-subtitle>
      <slot name="header" :options="options" :records="dataRecords"></slot>
      <v-divider class="my-2"></v-divider>
      <v-card-text :style="{ height: options.height }">
        <v-row no-gutters class="mb-2">
          <v-col>
            <v-btn class="float-right" small color="accent" @click="onBtnExport">
              <v-icon small>mdi-download</v-icon>エラー情報ダウンロード
            </v-btn>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <ag-grid-vue
              class="ag-theme-alpine"
              :gridOptions="gridOptions"
              v-model="dataRecords"
              @grid-ready="onGridReady"
              @row-data-changed="onRowDataChanged"
              style="height: 400px"
              :alwaysShowHorizontalScroll="true"
            ></ag-grid-vue>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="onClose">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<script>
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import { CheckboxCellRenderer } from "../../components/ag-grid/cellRenderers";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
  EditableColumn,
  EditableDateColumn,
} from "../../models/ag-grid/columnTypes";
import { DateCellEditor, CheckboxCellEditor } from "../../components/ag-grid/cellEditors";

export default {
  name: "ErrorGridDialog",
  props: {
    icon: { type: String, default: "mdi-alert-circle-outline" },
    title: { type: String, default: "エラー一覧" },
    subtitle: { type: String, default: null },
    width: { type: String, default: "100%" },
    height: { type: String, default: "350px" },
    columns: { type: Array, default: () => [] },
    records: { type: Array, default: () => [] },
    messageField: { type: String, default: "_messages" },
    autoClose: { type: Boolean, default: true },
  },
  components: {
    AgGridVue,
  },
  data: function () {
    return {
      show: false,
      options: {
        icon: this.icon,
        title: this.title,
        subtitle: this.subtitle,
        btnSubmit: this.btnSubmit,
        btnCancel: this.btnCancel,
        width: this.width,
        height: this.height,
        messageField: this.messageField,
        autoClose: this.autoClose,
      },
      callback: {
        submit: null,
        cancel: null,
      },
      gridOptions: {
        defaultColDef: {
          resizable: true,
          sortable: false,
          filter: false,
          editable: false,
        },
        columnDefs: this.columns,
        rowSelection: false,
        suppressCellSelection: true,
        singleClickEdit: true,
        pagination: false,
        localeText: AG_GRID_LOCALE_JA,
        suppressCsvExport: false,
        suppressExcelExport: true,
        columnTypes: {
          dpNumericColumn: { ...NumericColumn, filter: false },
          dpPercentColumn: { ...PercentColumn, filter: false },
          dpDateColumn: DateColumn,
          dpFullDateColumn: FullDateColumn,
          dpCheckmarkColumn: CheckmarkColumn,
          dpEditableColumn: EditableColumn,
          dpEditableDateColumn: EditableDateColumn,
        },
        frameworkComponents: {
          dpDateCellEditor: DateCellEditor,
          dpCheckboxCellRenderer: CheckboxCellRenderer,
          dpCheckboxCellEditor: CheckboxCellEditor,
        },
      },
      dataRecords: this.records,
    };
  },
  watch: {
    icon(value) {
      this.options.icon = value;
    },
    title(value) {
      this.options.title = value;
    },
    subtitle(value) {
      this.options.subtitle = value;
    },
    btnSubmit(value) {
      this.options.btnSubmit = value;
    },
    btnCancel(value) {
      this.options.btnCancel = value;
    },
    width(value) {
      this.options.width = value;
    },
    height(value) {
      this.options.height = value;
    },
    columns(value) {
      this.gridOptions.api.setColumnDefs(value);
    },
    records(value) {
      this.dataRecords = value;
      this.gridOptions.api.resetRowHeights();
    },
  },
  methods: {
    onGridReady(params) {
      params.api.resetRowHeights();
    },
    open(options = {}) {
      if ("icon" in options) this.options.icon = options.icon;
      if ("title" in options) this.options.title = options.title;
      if ("subtitle" in options) this.options.subtitle = options.subtitle;
      if ("btnClose" in options) this.options.btnClose = options.btnClose;
      if ("width" in options) this.options.width = options.width;
      if ("height" in options) this.options.width = options.height;
      if ("columns" in options) {
        if (this.gridOptions.api) {
          this.gridOptions.api.setColumnDefs(options.columns);
        } else {
          this.gridOptions.columnDefs = options.columns;
        }
      }
      if ("records" in options) {
        this.dataRecords = options.records;
        if (this.gridOptions.api) {
          this.gridOptions.api.resetRowHeights();
        }
      }
      this.callback.close = options.onClose;
      this.show = true;
    },
    close() {
      this.show = false;
    },
    onClose() {
      this.close();
      this.$emit("close");
      if (typeof this.callback.close === "function") {
        this.callback.close();
      }
    },
    onRowDataChanged() {
      this.gridOptions.columnApi.autoSizeAllColumns();
    },
    onBtnExport() {
      var params = {
        fileName: this.options.title + ".csv",
        allColumns: false,
      };
      this.gridOptions.api.exportDataAsCsv(params);
    },
  },
};
</script>
