// Value Formatters - https://www.ag-grid.com/javascript-grid/value-formatters/
import moment from "moment";

const NumberValueFormatter = (prefix, suffix) => {
  const _prefix = prefix == null ? "" : prefix;
  const _suffix = suffix == null ? "" : suffix;
  return (params) => {
    if (params.value == null) {
      return null;
    }
    if (String(params.value).trim().length === 0) {
      return null;
    }
    const value = Number(params.value).toLocaleString();
    return `${_prefix}${value}${_suffix}`;
  };
};

const NumberDecimalValueFormatter = (prefix, suffix) => {
  const _prefix = prefix == null ? "" : prefix;
  const _suffix = suffix == null ? "" : suffix;
  return (params) => {
    if (params.value == null) {
      return null;
    }
    if (String(params.value).trim().length === 0) {
      return null;
    }
    const value = Math.round(Number(params.value) * 100) / 100;
    return `${_prefix}${value.toLocaleString()}${_suffix}`;
  };
};

const StringValueFormatter = (prefix, suffix) => {
  const _prefix = prefix == null ? "" : prefix;
  const _suffix = suffix == null ? "" : suffix;
  return (params) => {
    return params == null || params.value == null ? null : `${_prefix}${params.value}${_suffix}`;
  };
};

const DateValueFormatter = (format) => {
  const defaultFormat = "YYYY/MM/DD HH:mm:ss";
  const _format = format == null || format.length === 0 ? defaultFormat : format;
  return (params) => {
    if (params == null || params.value == null) {
      return null;
    }
    const date = moment(new Date(params.value));
    return date.isValid() ? date.format(_format) : null;
  };
};

export { NumberDecimalValueFormatter, NumberValueFormatter, StringValueFormatter, DateValueFormatter };
