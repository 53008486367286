<template>
  <span class="ag-cell" @click="onClick">
    <i
      class="mdi v-icon"
      :class="{
        'mdi-checkbox-marked': value,
        'mdi-checkbox-blank-outline': !value,
      }"
      style="cursor: pointer"
    ></i>
  </span>
</template>

<script>
// import Vue from "vue";
import VueJS from "vue/dist/vue.js";

export default VueJS.extend({
  data() {
    return {
      value: null,
    };
  },
  methods: {
    getValue() {
      return this.value;
    },
    isCancelBeforeStart() {
      return false;
    },
    isCancelAfterEnd() {
      return false;
    },
    onClick() {
      this.value = !this.value;
    },
  },
  mounted() {
    this.value = this.params.value;
  },
});
</script>
